export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12')
];

export const server_loads = [];

export const dictionary = {
		"/(app)": [4,[2],[3]],
		"/(app)/register": [6,[2],[3]],
		"/(app)/schematic/new": [10,[2],[3]],
		"/(app)/schematic/[slug]": [7,[2],[3]],
		"/(app)/schematic/[slug]/download": [8,[2],[3]],
		"/(app)/schematic/[slug]/edit": [9,[2],[3]],
		"/(app)/sign-in": [11,[2],[3]],
		"/(app)/user/[slug]": [12,[2],[3]],
		"/(app)/[...catchall]": [5,[2],[3]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';